import "../index.scss";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DiyRepairTypeahead from "../../DiyRepairTypeahead";
import TradesTypeaheadField from "../../../ui/FormFields/trades.typeahead";
// import CreateFeatureModal from "../../../ui/FeatureModal";
import { connect } from "react-redux";
import {
  sortByArea,
  updateFeatureRefState,
  updateCreatedFeature,
} from "../../../actions";
import axios, { v2 } from "../../../services/axios";
import QueuedProjectsModal from "../../../ui/QueuedProjectsModal";

const mapStateToProps = (state) => ({
  featureRef: state.featureRef.featureRef,
  sortedRooms: state.properties.sortedRooms,
  createdFeature: state.properties.createdFeature,
});

export default
@withRouter
@connect(mapStateToProps, {
  sortByArea,
  updateFeatureRefState,
  updateCreatedFeature,
})
class CreateProject extends Component {
  state = {
    area_id: null,
    area_ref_id: null,
    errors: {},
    project: {},
    feature_name: null,
    feature_problem_override: false,
    image: "/assets/images/DomoReportImage.png",
    is_planning: false,
    loading: false,
    material_quality: null,
    // showCreateFeature: false,
    showProjectEntry: false,
    showConfirmModal: false,
  };

  componentDidMount() {
    const { comment, page } = this.props;

    // Set inspection problem comment/page # provided by context menu
    if (comment) {
      this.updateProject("inspection_problem_comment", comment);
      this.updateProject("inspection_page_num_start", page);
      this.updateProject("inspection_page_num_end", page);
      this.setState({ showProjectEntry: true })
    }

    this.props.updateFeatureRefState("area_ref_id", null);
    this.handleChange("area_id", null);
    // this.props.sortByArea(property.property_log_id);
    document.getElementsByClassName("md-diy-sidebar")[0].scrollTo(0, 0);
  }

  componentDidUpdate() {
    const { project } = this.state;
    const { comment, page } = this.props;

    if(comment) {
      const els = document.getElementsByClassName("rbt-input-main")
      if (els.length > 0) {
        setTimeout(() => {
            els[0].classList.add("focus");
            els[0].click();
            els[0].focus();
        }, 10);
      }
      this.props.setContextComment(null);
      this.props.setContextPage(null);
    }

    // Set inspection problem comment provided by context menu
    if (
      (comment && !project.inspection_problem_comment) ||
      (comment &&
        project.inspection_problem_comment &&
        comment !== project.inspection_problem_comment)
    ) {
      this.updateProject("inspection_problem_comment", comment);
      this.props.setContextComment(null);
      this.props.setContextPage(null);
    }

    // Set inspection pages provided by context menu
    if (
      (page && !project.inspection_page_num_start) ||
      (page &&
        project.inspection_page_num_start &&
        page !== project.inspection_page_num_start)
    ) {
      this.updateProject("inspection_page_num_start", page);
      this.updateProject("inspection_page_num_end", page);
      this.props.setContextPage(null);
    }
  }

  componentWillUnmount() {
    this.props.setContextComment(null);
    this.props.setContextPage(null);
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  updateProject = (key, value) => {
    const { project, feature_problem_override, errors } = this.state;
    let allErrors = { ...errors, [key]: false };
    let updatedProject = project;

    if (key === "inspection_problem_comment" && !feature_problem_override) {
      let title = value.split(".");
      updatedProject.inspection_problem_comment = value
        .replace(/\0/g, "")
        .replace(/  +/g, " ");
      updatedProject.feature_problem = title[0]
        .replace(/\0/g, "")
        .replace(/  +/g, " ")
        .trim();
      allErrors.feature_problem = false;
    } else {
      updatedProject[key] = value;
    }

    if (key === "feature_problem") {
      this.setState({ feature_problem_override: true });
    }

    if (key === "cache_project_name") {
      allErrors.cache_project_name = false;
    }

    this.setState({ project: updatedProject, errors: allErrors });
  };

  handleNewFeature = (e) => {
    e.preventDefault();
    this.setState({ showCreateFeature: true });
  };

  handleDefaultFeatureProblem = () => {
    const { project } = this.state;
    let comment = project.inspection_problem_comment.split(".");

    this.setState({ feature_problem_override: false });
    this.updateProject("feature_problem", comment[0]);
  };

  handlePlanningToggle = (e) => {
    const { job_details_override, cache_job_details } = this.state.project;
    const planningText = "This estimate is for planning purposes. ";
    let currentText = job_details_override || cache_job_details;

    if (e.target.checked) {
      this.updateProject("job_details_override", planningText + currentText);
    } else {
      currentText = currentText.replace(
        "This estimate is for planning purposes. ",
        ""
      );
      this.updateProject("job_details_override", currentText);
    }
  };

  getColor = (priority) => {
    if (priority === "1" || priority === 1) {
      return "#b2140a";
    } else if (priority === "2" || priority === 2) {
      return "#fea500";
    } else if (priority === "3" || priority === 3) {
      return "#003782";
    } else {
      return "#b2140a";
    }
  };

  submitProject = (e) => {
    e.preventDefault();
    const { reportID } = this.props;
    const { project } = this.state;

    let data = {
      domoreport_log_id: reportID,
      feature_problem: project.feature_problem,
      inspection_page_num_start: project.inspection_page_num_start,
      inspection_page_num_end: project.inspection_page_num_end,
      inspection_problem_comment: project.inspection_problem_comment.trim(),
      xactware_project_id: project.xactware_project_id,
      xactware_quantity: project.xactware_quantity,
      cache_unit_type_ref_id: project.cache_unit_type_ref_id,
      xactware_quality: project.xactware_quality,
      project_name_override: project.project_name_override,
      cache_project_name: project.cache_project_name,
      cache_project_cost_low: project.cache_project_cost_low,
      cache_project_cost_high: project.cache_project_cost_high,
      cache_material_cost_low: project.cache_material_cost_low,
      cache_material_cost_high: project.cache_material_cost_high,
      cache_labor_cost_low: project.cache_labor_cost_low,
      cache_labor_cost_high: project.cache_labor_cost_high,
      cache_equipment_cost_low: project.cache_equipment_cost_low,
      cache_equipment_cost_high: project.cache_equipment_cost_high,
      cache_licensed_contractor_cost_low:
        project.cache_licensed_contractor_cost_low,
      cache_licensed_contractor_cost_high:
        project.cache_licensed_contractor_cost_high,
      low_market_price: project.low_market_price,
      high_market_price: project.high_market_price,
      cache_job_details: project.cache_job_details,
      job_details_override: project.job_details_override,
      project_priority_ref_id: project.project_priority_ref_id || 1,
      trade_id: project.trade_id,
      repair_wise_job_id: project.repair_wise_job_id,
      repair_wise_estimate_id: project.repair_wise_estimate_id,
    };

    const errors = {
      feature_problem: !project.feature_problem,
      inspection_page_num_start: !project.inspection_page_num_start,
      inspection_page_num_end: !project.inspection_page_num_end,
      inspection_problem_comment: !project.inspection_problem_comment,
      xactware_project_id: !project.xactware_project_id,
      xactware_quantity: !project.xactware_quantity,
      xactware_quality: !project.xactware_quality,
      // project_name_override: !project.project_name_override,
      // job_details_override: !project.job_details_override,
      cache_project_name: !project.cache_project_name,
      cache_job_details: !project.cache_job_details,
      project_priority_ref_id: !project.project_priority_ref_id,
      trade_id: !project.trade_id,
    };

    let validProject = true;
    for (var i in errors) {
      if (errors[i]) {
        validProject = false;
        break;
      }
    }

    if (validProject) {
      this.createProject(data);
    } else {
      this.handleChange("errors", errors);
    }
  };

  createProject = async (data) => {
    try {
      const createdProjectResponse = await axios.post(
        `/domoreport/uploader/project`,
        data
      );
      if (createdProjectResponse.data) {
        // this.props.getProjects("priceHigh");
        // this.props.updateCreatedFeature("property_fact_id", null);
        this.props.updateStep(1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  queueProject = async () => {
    const { report } = this.props;
    const { project } = this.state;
    let queuedProjects = JSON.parse(
      localStorage.getItem("queued-projects")
    ) || { [report.unique_id]: [] };

    if (
      queuedProjects &&
      queuedProjects[Object.keys(queuedProjects)[0]] &&
      queuedProjects[Object.keys(queuedProjects)[0]].length &&
      Object.keys(queuedProjects)[0] !== report.unique_id
    ) {
      this.handleChange("showConfirmModal", true);
    } else {
      if (
        !queuedProjects[report.unique_id] ||
        !queuedProjects[report.unique_id].length
      ) {
        queuedProjects = { [report.unique_id]: [] };
      }

      queuedProjects[report.unique_id].push({
        text: project.inspection_problem_comment
          .replace(/\0/g, "")
          .replace(/  +/g, " ")
          .trim(),
        pageStart: project.inspection_page_num_start,
        pageEnd: project.inspection_page_num_end,
      });

      localStorage.setItem("queued-projects", JSON.stringify(queuedProjects));
      this.props.updateStep(1);
    }
  };

  toggleShowProjectEntry = (e) => {
    e.preventDefault();
    this.setState({ showProjectEntry: !this.state.showProjectEntry });
  };

  handleOverwrite = () => {
    const { project } = this.state;
    const { report } = this.props;
    const currentProjects = {
      [report.unique_id]: [
        {
          text: project.inspection_problem_comment
            .replace(/\0/g, "")
            .replace(/  +/g, " ")
            .trim(),
          pageStart: project.inspection_page_num_start,
          pageEnd: project.inspection_page_num_end,
        },
      ],
    };

    localStorage.setItem("queued-projects", JSON.stringify(currentProjects));
    this.props.updateStep(1);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { reportID, report } = this.props;
    const { selection } = this.state;

    try {
      const queued = JSON.parse(localStorage.getItem("queued-projects"));
      const keys = Object.keys(queued);
      if (keys.length && keys[0]) {
        const { status } = await v2.post(
          `/domoreports/${keys[0]}/automation/defects/`,
          queued[keys[0]]
        );
        if (status === 201) {
          this.props.setMessage("Projects submitted for classification");
          this.props.getOneReport(reportID);
          localStorage.removeItem("queued-projects");
          this.queueProject({ [report.unique_id]: [] }, selection);
          this.handleChange("showConfirmModal", false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { property, sortedRooms } = this.props;
    const {
      material_quality,
      project,
      errors,
      showConfirmModal,
    } = this.state;

    let elementIndex = 0;

    return (
      <div className="md-diy-sidebar-content">
        {/* {showCreateFeature && (
          <CreateFeatureModal
            create={true}
            handleChange={this.handleChange}
            propertyId={property.property_log_id}
            showCreateFeature={showCreateFeature}
            recommendedFeature={project.feature_id}
            updateProject={this.updateProject}
          />
        )} */}

        <QueuedProjectsModal
          show={showConfirmModal}
          close={() => this.handleChange("showConfirmModal", false)}
          handleOverwrite={this.handleOverwrite}
          handleSubmit={this.handleSubmit}
        />

        <div className="md-diy-sidebar-info">
          <h1 className="md-diy-sidebar-header">Create Project</h1>
          {/* <div
            className="md-diy-sidebar-image-wrapper"
            style={{ backgroundImage: `url(${image})` }}
          /> */}
        </div>

        <div className="md-diy-sidebar-form">
          <label>Defect Description</label>
          <div className="md-diy-input-wrapper">
            <img
              src="/assets/icons/Description.svg"
              className="md-diy-input-icon"
              alt=""
            />
            <textarea
              rows="5"
              placeholder="Add defect description here"
              className={`${
                errors.inspection_problem_comment ? "invalid" : ""
              }`}
              value={project.inspection_problem_comment || ""}
              onChange={(e) =>
                this.updateProject("inspection_problem_comment", e.target.value)
              }
            />
          </div>

          <label>
            Defect Title&nbsp;
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.handleDefaultFeatureProblem();
              }}
            >
              Use Default
            </a>
          </label>
          <div className="md-diy-input-wrapper">
            <img
              src="/assets/icons/Project.svg"
              className="md-diy-input-icon"
            />
            <input
              type="text"
              placeholder="One or more unsealed holes"
              className={`${errors.feature_problem ? "invalid" : ""}`}
              value={project.feature_problem || ""}
              onChange={(e) =>
                this.updateProject("feature_problem", e.target.value)
              }
            />
          </div>

          <div className="md-diy-input-wrapper row">
            <div className="col-6">
              <label>PDF Page Start</label>
            </div>
            <div className="col-6">
              <label>PDF Page End</label>
            </div>
          </div>
          <div className="md-diy-input-wrapper row">
            <div className="col-6">
              <img src="/assets/icons/Page.svg" className="md-diy-input-icon" />
              <input
                type="text"
                placeholder="Page Start #"
                className={`${
                  errors.inspection_page_num_start ? "invalid" : ""
                }`}
                value={project.inspection_page_num_start || ""}
                onChange={(e) =>
                  this.updateProject(
                    "inspection_page_num_start",
                    parseInt(e.target.value)
                  )
                }
              />
            </div>
            <div className="col-6">
              <img src="/assets/icons/Page.svg" className="md-diy-input-icon" />
              <input
                type="text"
                placeholder="Page End #"
                className={`${errors.inspection_page_num_end ? "invalid" : ""}`}
                value={project.inspection_page_num_end || ""}
                onChange={(e) =>
                  this.updateProject(
                    "inspection_page_num_end",
                    parseInt(e.target.value)
                  )
                }
              />
            </div>
          </div>

          {this.state.showProjectEntry ? (
            <div>
              <div className="create-project-toggle">
                <a href="#" onClick={this.toggleShowProjectEntry}>
                  Queue Project for Machine
                </a>
              </div>

              <div className="md-diy-input-wrapper">
                <DiyRepairTypeahead
                  updateProject={this.updateProject}
                  handleChange={this.handleChange}
                  errors={errors}
                  quality={material_quality}
                  project={project}
                  property={property}
                  sortedRooms={sortedRooms}
                  src="create"
                />
              </div>

              {project && project.cache_job_details && (
                <div>
                  <label>
                    Project Name Override
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.updateProject("project_name_override", null);
                      }}
                    >
                      {" "}
                      Use Default
                    </a>
                  </label>
                  <div className="md-diy-input-wrapper">
                    <img
                      src="/assets/icons/Description.svg"
                      className="md-diy-input-icon"
                    />
                    <input
                      type="text"
                      placeholder="Add project name here"
                      className={`${
                        errors.cache_project_name ? "invalid" : ""
                      }`}
                      value={
                        project.project_name_override != null
                          ? project.project_name_override
                          : project.cache_project_name
                      }
                      onChange={(e) =>
                        this.updateProject(
                          "project_name_override",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <label className="project-description-label">
                    <div>
                      Project Description
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateProject("job_details_override", null);
                        }}
                      >
                        {" "}
                        Use Default
                      </a>
                    </div>

                    <div className="planning-toggle">
                      <input
                        type="checkbox"
                        name="planning"
                        id="planning"
                        onClick={this.handlePlanningToggle}
                      />
                      <label htmlFor="planning">For Planning?</label>
                    </div>
                  </label>

                  <div className="md-diy-input-wrapper">
                    <img
                      src="/assets/icons/Description.svg"
                      className="md-diy-input-icon"
                    />
                    <textarea
                      rows="5"
                      placeholder="Add project description here"
                      className={`${errors.cache_job_details ? "invalid" : ""}`}
                      value={
                        project.job_details_override ||
                        project.cache_job_details ||
                        ""
                      }
                      onChange={(e) =>
                        this.updateProject(
                          "job_details_override",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              )}

              {((project && project.xactware_quality) ||
                material_quality !== null) && (
                <div>
                  <label>Trade</label>
                  <div className="md-diy-input-wrapper">
                    <img
                      src="/assets/icons/Project.svg"
                      className="md-diy-input-icon"
                    />
                    <TradesTypeaheadField
                      value={project.trade_id}
                      keyAsIndex={elementIndex++}
                      updateProject={this.updateProject}
                    />
                  </div>

                  <hr />

                  <label>Project Priority</label>
                  <div className="md-diy-input-wrapper">
                    <div
                      className="md-diy-input-icon priority"
                      style={{
                        backgroundColor: this.getColor(
                          project.project_priority_ref_id
                        ),
                      }}
                    />
                    <select
                      id="priority"
                      className={`${
                        errors.project_priority_ref_id ? "invalid" : ""
                      }`}
                      onChange={(e) =>
                        this.updateProject(
                          "project_priority_ref_id",
                          e.target.value
                        )
                      }
                      value={project.project_priority_ref_id || 1}
                    >
                      <option value={1}>Urgent</option>
                      <option value={2}>Pressing</option>
                      <option value={3}>Cosmetic</option>
                    </select>
                  </div>

                  <hr />

                  {/* <label>Feature</label>
                  <div
                    className={
                      loading
                        ? "md-diy-input-wrapper disabled"
                        : "md-diy-input-wrapper"
                    }
                  >
                    <img
                      src="/assets/icons/Project.svg"
                      className="md-diy-input-icon"
                    />
                    <select
                      id="features"
                      value={project.property_fact_id || ""}
                      onChange={(e) =>
                        this.updateProject(
                          "property_fact_id",
                          parseInt(e.target.value)
                        )
                      }
                      className={`${
                        !project.property_fact_id ? "feature-disabled" : ""
                      } ${errors.property_fact_id ? "invalid" : ""}`}
                      disabled={loading}
                    >
                      <option value="" disabled>
                        None
                      </option>
                      {sortedRooms.map((section) => {
                        return section.rooms.map((room) => {
                          return (
                            <optgroup
                              label={room.area_name + " #" + room.area_instance}
                            >
                              {room.features.map((feature) => {
                                if (feature.is_exists) {
                                  return (
                                    <option
                                      key={feature.property_fact_id}
                                      value={feature.property_fact_id}
                                    >
                                      {feature.feature_name} ({room.area_name})
                                    </option>
                                  );
                                }
                              })}
                            </optgroup>
                          );
                        });
                      })}
                    </select>

                    <a href="#" onClick={this.handleNewFeature}>
                      + Create New Feature
                    </a>
                  </div> */}
                </div>
              )}
            </div>
          ) : (
            <div className="create-project-toggle">
              <a href="#" onClick={this.toggleShowProjectEntry}>
                Manually Create Project
              </a>
            </div>
          )}

          <button
            className="btn btn-primary"
            onClick={
              this.state.showProjectEntry
                ? this.submitProject
                : this.queueProject
            }
          >
            {this.state.showProjectEntry ? "Create" : "Queue Project"}
          </button>
          <a
            href="#"
            className="btn btn-danger"
            onClick={() => this.props.updateStep(1)}
          >
            Cancel
          </a>
        </div>
      </div>
    );
  }
}
